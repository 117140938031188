
































import {Prop} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';

import storeUser from '@/stores/modules/user';

import Checked from '@/components/Checked.vue';
import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';

@Component({
	components: {
		Checked,
		CrudList,
		TableHead,
		TableCell,
	}
})
export default class CrudUserList extends Vue {
	
	@Prop({type: Boolean, default: true  }) private paginate;
	@Prop({type: Boolean, default: false }) private dense;
	
	private storeUser = storeUser;

	public mounted(): void {
		storeUser.dispatch('getC');
	}

	private headers = [
		{
			text: 'Status',
			value: 'enable',
		},
		{ text: 'Email', value: 'email' },
		{ text: 'Nom', value: 'lastName' },
		{ text: 'Prénom', value: 'firstName' },
		{ text: 'Téléphone', value: 'phone' },
		{ text: 'Roles', value: 'roles' },
		{ text: 'Date création', value: 'createdAt' },
		{ text: 'Actions', value: 'action' },
	];
}
