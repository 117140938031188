


































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {AvisLoyer, Locataire} from '@/models';
import {PeriodicityType} from '@/models';
import storeAvisLoyer from '@/stores/modules/avisLoyer';
import storeLogement from '@/stores/modules/logement';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";
import InputDatePicker from "@/components/Input/DatePicker.vue";
import CrudLogementListSelect from "@/components/Crud/Logement/ListSelect.vue";
import {Emit} from "vue-property-decorator";
import store from "@/stores";
import storeTva from "@/stores/modules/tva";
import storeLocataire from "@/stores/modules/locataire";
import {InOut} from "vue-inout-prop-decorator/index";

@Component({
	components: {
		InputPhone,
		InputZipCode,
		InputDatePicker,
		CrudLogementListSelect,
	}
})
export default class CrudAvisLoyerEdit extends AbstractEditVue<AvisLoyer> {

	@InOut({ type: Locataire }) private locataire!: Locataire;

	public constructor() {
		super(
			storeAvisLoyer,
			'AvisLoyer modifié',
			'AvisLoyer ajouté',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		await storeAvisLoyer.dispatch('getC');
	}

	protected async onSubmit(): Promise<void> {
		let array = [this.locataire.id,this.value.date]
		await storeLocataire.dispatch('createAvisLoyer', array);
		console.log('here');
		this.$emit('on-submit', this.value);
	}

}
