








































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {AvisLoyer} from '@/models';

import CrudAvisLoyerEditReglements from '@/components/Crud/AvisLoyer/EditReglements.vue';

@Component({
	components: {
		CrudAvisLoyerEditReglements,
	}
})
export default class CrudAvisLoyerEditDialogReglement extends Vue {
	@InOut({ type: AvisLoyer  , isVModel: true }) private value!: AvisLoyer;

	@Emit()
	private onSubmit(avisLoyer: AvisLoyer) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
