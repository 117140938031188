


















import {Prop} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';

import storeTva from '@/stores/modules/tva';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
	}
})
export default class CrudTvaList extends Vue {
	
	@Prop({type: Boolean, default: true  }) private paginate!: boolean;
	@Prop({type: Boolean, default: false }) private dense!   : boolean;
	
	private storeTva = storeTva;

	public mounted(): void {
		storeTva.dispatch('getC');
	}

	private headers = [
		{
			text: 'Pourcentage de la TVA',
			value: 'purcentage',
		},
		{ text: 'Actions', value: 'action' },
	];

}
