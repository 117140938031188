




























































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Locataire, Proprietaire} from '@/models';

import CrudLocataireFilter from '@/components/Crud/Locataire/Filter.vue';
import CrudLocataireEditDialog from '@/components/Crud/Locataire/EditDialog.vue';
import CrudLocataireList from '@/components/Crud/Locataire/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeLocataire, {LocataireFilter} from '@/stores/modules/locataire';

@Component({
	components: {
		CrudLocataireFilter,
		CrudLocataireEditDialog,
		CrudLocataireList,
		CrudDeleteDialog,
	}
})
export default class LocataireView extends Vue {

	@Stored(() => storeLocataire)
	private livraisons!: ResultType<Locataire>;
	@Stored(() => storeLocataire) private filters!: LocataireFilter;

	private editLocataire: Locataire = null;
	private deletedLivraison: Locataire = null;
	private storeLocataire = storeLocataire;

	private refresh(): void {
		this.filters.state = [true];
		storeLocataire.dispatch('getC', {filters: true});
	}

	@Emit()
	private onCreate(): void {
		this.editLocataire = new Locataire();
	}
}
