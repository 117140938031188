







































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {AvisLoyer} from '@/models';

	import CrudAvisLoyerEditAvisLoyer from '@/components/Crud/AvisLoyer/EditAvisLoyer.vue';
	import storeAvisLoyer from "@/stores/modules/avisLoyer";

	@Component({
		components: {
			CrudAvisLoyerEditAvisLoyer,
		}
	})
	export default class CrudAvisLoyerEditAvisLoyerDialog extends Vue {
		@InOut({ type: AvisLoyer  , isVModel: true }) private value!: AvisLoyer;

		@Emit()
		private onSubmit(avisLoyer: AvisLoyer) {
			this.onClose();
		}

		@Emit()
		private async onClose(): Promise<void> {
			this.value = null;
		}

}
