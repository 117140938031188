

































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Tva} from '@/models';

import storeTva from '@/stores/modules/tva';

import InputDatePicker from '@/components/Input/DatePicker.vue';

@Component({
	components: {
		InputDatePicker,
	}
})
export default class CrudTvaEdit extends AbstractEditVue<Tva> {
	public constructor() {
		super(
			storeTva,
			'TVA modifié',
			'TVA ajouté',
		);
	}
}
