










import {Prop, Watch} from "vue-property-decorator";
import {ObjectConstructor} from "gollumts-objecttype";
import {Component, Vue} from '@/shared/component';
import {Route} from "vue-router";

@Component()
export default class EditView extends Vue {

	@Prop({ type: Function, required: true }) private slotData!: () => ({
		component: Promise<any>,
		class: any,
	});
	@Prop({ type: Object, default: null }) private editRoute!: Route;

	private componentClass: ObjectConstructor<Vue> = null;
	private entity: any = null;
	private item: any = null;
	private loaded: boolean = false;

	public async mounted(): Promise<void> {
		await this.refresh();
	}

	public get editEnable(): boolean {
		if (this.$route.meta.editCallback) {
			return this.$route.meta.editCallback(this.item);
		}
		return true;
	}
	
	private editRouteGranted(): boolean {
		if(this.editRoute) {
			const route = this.$router.resolve(this.editRoute);
			if (route.route.meta.granted) {
				return this.isGranted(route.route.meta.granted);
			}
		}
		return true;
	}

	@Watch('$route')
	private async refresh(): Promise<void> {

		this.loaded = false;

		const id = parseInt(this.$route.params.id, 10);
		if (Number.isNaN(id)) {
			this.$router.push({name: 'home'});
			return;
		}

		const slotData = this.slotData();

		this.componentClass = (await slotData.component).default;
		const entityClass = await slotData.class;

		this.entity = new entityClass();
		this.entity._id = id;

		await this.$nextTick();

		this.loaded = true;
	}

}
