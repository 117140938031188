








































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {AvisLoyer, Locataire} from '@/models';

	import CrudAvisLoyerEdit from '@/components/Crud/AvisLoyer/Edit.vue';
	import storeAvisLoyer from "@/stores/modules/avisLoyer";
	import storeLocataire from "@/stores/modules/locataire";

	@Component({
		components: {
			CrudAvisLoyerEdit,
		}
	})
	export default class CrudAvisLoyerEditDialog extends Vue {
		@InOut({ type: AvisLoyer  , isVModel: true }) private value!: AvisLoyer;
		@InOut({ type: Locataire }) private locataire!: Locataire;

		@Emit()
		private onSubmit() {
			console.log('ok');
			this.onClose();
		}

		@Emit()
		private async onClose(): Promise<void> {
			await storeLocataire.dispatch('get', this.locataire.id);
			this.value = null;
		}

}
