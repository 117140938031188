





























































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Logement, Proprietaire} from '@/models';

import CrudLogementFilter from '@/components/Crud/Logement/Filter.vue';
import CrudLogementEditDialog from '@/components/Crud/Logement/EditDialog.vue';
import CrudLogementList from '@/components/Crud/Logement/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeLogement from '@/stores/modules/logement';

@Component({
	components: {
		CrudLogementFilter,
		CrudLogementEditDialog,
		CrudLogementList,
		CrudDeleteDialog,
	}
})
export default class LogementView extends Vue {

	@Stored(() => storeLogement)
	private livraisons!: ResultType<Logement>;

	private editLogement: Logement = null;
	private deletedLivraison: Logement = null;
	private storeLogement = storeLogement;

	private refresh(): void {
		storeLogement.dispatch('getC');
	}

	@Emit()
	private onCreate(): void {
		this.editLogement = new Logement();
	}
}
