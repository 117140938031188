





















import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeAvisLoyer, {AvisLoyerFilter} from '@/stores/modules/avisLoyer';
import storeLocataire, {LocataireFilter} from '@/stores/modules/locataire';

import InputNumberFilter from '@/components/Input/NumberFilter.vue';
import InputTva from '@/components/Input/Tva.vue';
import {ArrayHelper} from "@/shared/utils";

import InputDateFilter from '@/components/Input/DateFilter.vue';
import storeProprietaire from "@/stores/modules/proprietaire";

@Component({
	components: {
		InputDateFilter,
		InputNumberFilter,
		InputTva,
	}
})
export default class CrudAvisLoyerFilter extends Vue {

	@Prop({ type: Boolean, default: false}) reduce!: boolean;

	@Stored(() => storeAvisLoyer) private page!: number;
	@Stored(() => storeAvisLoyer) private filters!: AvisLoyerFilter;

	private storeProprietaire = storeProprietaire
	private valueProprietaire = null;

	@WatchObjectProperties('filters', AvisLoyerFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeAvisLoyer.dispatch('getC', { filters: true });
	}

	public async mounted(): Promise<void> {
		await storeProprietaire.dispatch('getAll');
		this.valueProprietaire = storeProprietaire.state.all.data
		this.typeWatcher();
		this.filters = new AvisLoyerFilter();
	}

	@Watch('type')
	private typeWatcher(): void {
		this.filters.clear();
	}

}
