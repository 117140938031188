





























import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeProprietaire, {ProprietaireFilter} from '@/stores/modules/proprietaire';

import InputDateFilter from '@/components/Input/DateFilter.vue';

@Component({
	components: {
		InputDateFilter,
	}
})
export default class CrudProprietaireFilter extends Vue {

	@Prop({ type: Boolean, default: false}) reduce;

	@Stored(() => storeProprietaire) private page!: number;
	@Stored(() => storeProprietaire) private filters!: ProprietaireFilter;

	@WatchObjectProperties('filters', ProprietaireFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeProprietaire.dispatch('getC', { filters: true });
	}

	public mounted() {
	    this.filters = new ProprietaireFilter();
	}
}
