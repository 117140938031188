













import {Store} from "vuex";
import {Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {PaginatorState} from "@/shared/utils";

@Component()
export default class InputGenericEntitySelect extends Vue {

	@Prop ({ type: Store , required: true }) private store!: Store<PaginatorState>;
	@Prop ({ type: Function , required: false, default: () => ( () => true ) }) private itemFilter!: () => boolean;
	@Prop({ type: Boolean, default: false }) multiple!: boolean;
	@InOut({ type: [ Object, Array ], isVModel: true }) private value!: any;
	
	public async mounted(): Promise<void> {
		await this.store.dispatch('getAll');
	}
}
