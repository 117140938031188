















































































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Tva} from '@/models';

import CrudTvaFilter from '@/components/Crud/Tva/Filter.vue';
import CrudTvaEditDialog from '@/components/Crud/Tva/EditDialog.vue';
import CrudTvaList from '@/components/Crud/Tva/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeTva from '@/stores/modules/tva';

@Component({
	components: {
		CrudTvaFilter,
		CrudTvaEditDialog,
		CrudTvaList,
		CrudDeleteDialog,
	}
})
export default class TvaView extends Vue {
	
	@Stored(() => storeTva)
	private Tvas!: ResultType<Tva>;
	
	private editTva: Tva = null;
	private deletedTva: Tva = null;
	private storeTva = storeTva;
	
	@Emit()
	private onCreate(): void {
		this.editTva = new Tva();
	}

	private refresh(): void {
		storeTva.dispatch('getC', { filters: true });
	}
}
