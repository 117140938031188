





































































































import {Component} from '@/shared/component';
import {Proprietaire} from '@/models';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';

import storeProprietaire from '@/stores/modules/proprietaire';
import CrudDocumentProprietaireUploader from "@/components/Crud/DocumentProprietaire/Uploader.vue";
import CrudDocumentProprietaireShow from "@/components/Crud/DocumentProprietaire/Show.vue";

@Component({
	components: {
		CrudDocumentProprietaireUploader,
		CrudDocumentProprietaireShow,
	}
})
export default class CrudProprietaireShow extends AbstractShowVue<Proprietaire> {
	public constructor() {
		super(storeProprietaire);
	}
}
