import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentProprietaire} from "@/models/DocumentProprietaire";
import {DocumentProprietaireService} from "@/xhttp/DocumentProprietaireService";
import store from '@/stores';
import proprietaire from "@/router/proprietaire";
import storeProprietaire from "@/stores/modules/proprietaire";

Vue.use(Vuex);

class DocumentProprietaireState {
}

class DocumentProprietaireStore {

	@XHTTPService(() => DocumentProprietaire)
	private documentService: DocumentProprietaireService;

	public state: DocumentProprietaireState = new DocumentProprietaireState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentProprietaireState, any>, document: DocumentProprietaire): Promise<DocumentProprietaire> {
			try {
				document.proprietaire.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				await storeProprietaire.dispatch('get', document.proprietaire.id);
				throw e;
			}
		}
	};

}
const instance = new DocumentProprietaireStore();
const storeDocumentProprietaire = new Vuex.Store(instance);
store.registerModule('document', storeDocumentProprietaire);
export default storeDocumentProprietaire;