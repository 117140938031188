var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.storeLogement.state.logements)?_c('v-data-table',{staticStyle:{"filter":"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"},attrs:{"headers":_vm.headers,"items":_vm.storeLogement.state.logements.data,"group-by":"proprietaire.fullName","items-per-page":-1,"search":_vm.search,"show-group-by":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"item.availability",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.availability),callback:function ($$v) {_vm.$set(item, "availability", $$v)},expression:"item.availability"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isGranted('API_PUT_LOGEMENT'))?_c('v-btn',{attrs:{"icon":"","color":"edit","title":"Éditer"},on:{"click":function($event){$event.stopPropagation();_vm.editLogement = item}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.isGranted('API_DELETE_LOGEMENT'))?_c('v-btn',{attrs:{"icon":"","color":"delete","title":"Supprimer"},on:{"click":function($event){$event.stopPropagation();_vm.deletedLogement = item}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1):_vm._e()]}}],null,false,2678392016)}):_vm._e()],1),_c('crud-logement-edit-dialog',{on:{"on-close":function($event){return _vm.refreshLogement()}},model:{value:(_vm.editLogement),callback:function ($$v) {_vm.editLogement=$$v},expression:"editLogement"}}),_c('crud-delete-dialog',{attrs:{"repeat-field":"id","title":"Cette action va supprimer définitivement le logement","label":"Pour le supprimer vous devez retaper son id:","store":_vm.storeLogement},on:{"on-deleted":function($event){return _vm.onDeleteLogement()}},model:{value:(_vm.deletedLogement),callback:function ($$v) {_vm.deletedLogement=$$v},expression:"deletedLogement"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }