import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {ObjectString} from 'gollumts-objecttype';
import {XHTTPService} from '@/shared/xhttp';
import {Uuid} from "@/shared/utils";
import {DocumentProprietaire, Proprietaire, Upload} from '@/models';
import {UploadService} from '@/xhttp';

import store from '@/stores';
import storeLoader from "@/stores/modules/loader";
import storeLogement from "@/stores/modules/logement";
import storeProprietaire from "@/stores/modules/proprietaire";

Vue.use(Vuex);


const getChunkFile = (file: File, offset: number, length): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>  resolve(<string>reader.result);
        reader.onerror = () =>  reject(reader.error);
        reader.readAsBinaryString(file.slice(offset, offset + length));
    });
};

const fileToUpload = (
    file: File,
    calbbackSend: (upload: Upload) => Promise<Upload>,
    calbbackEnd: (upload: Upload) => void = (upload: Upload) => {},
    fileName: string = null
): Upload => {

    let upload = new Upload();
    upload.uuid = Uuid.generate();
    upload.originalFileName = file.name;
    if (fileName) {
        upload.media.fileName = fileName;
    }

    // Call all chunck
    const finish = (async (): Promise<Upload> => {
        try {
            storeLoader.commit('push');

            const chunkSize = 1024 * 300; // 300ko (base 64 add 33% of size)
            let offset = 0;

            storeUpload.commit('setProgress', { upload, progress: 0 });
            let i = 0;
            const totalChunck = Math.ceil(file.size / chunkSize);
            while (!upload.endOfFile) {
                upload.endOfFile = chunkSize > file.size - offset;

                upload.data = btoa(
                    await getChunkFile(file, offset, chunkSize)
                );
                offset += chunkSize;
                upload = await calbbackSend(upload);
                i++;
                storeUpload.commit('setProgress', { upload, progress: i / totalChunck });
            }
            calbbackEnd(upload);

            setTimeout(() => {
                storeUpload.commit('clearProgress', upload);
            }, 2000);
            storeLoader.commit('pop');

        } catch (e) {
            setTimeout(() => {
                storeUpload.commit('clearProgress', upload);
            }, 2000);
            storeLoader.commit('pop');
            throw e;
        }
        return upload;
    })();

    storeUpload.commit('setFinish', { upload, finish });

    // Return result first chunck
    return upload;
};

class UploadState {
    finishs: ObjectString<Promise<Upload>> = {};
    progresses: ObjectString<number> = {};
}

class UploadStore {

    @XHTTPService(() => Upload)
    private uploadService: UploadService;

    public state: UploadState = new UploadState();

    public mutations = {
        setFinish(state: UploadState, { upload, finish }: { upload: Upload, finish: Promise<Upload> }) {
            const finishs = {};
            finishs[upload.uuid] = finish;
            state.finishs = { ...state.finishs, ...finishs };
        },

        setProgress(state: UploadState, { upload, progress }: { upload: Upload, progress: number }) {
            const progresses = {};
            progresses[upload.uuid] = progress;
            state.progresses = { ...state.progresses, ...progresses };
        },

        clearProgress(state: UploadState, upload: Upload) {
            const progresses = { ...state.progresses };
            delete(progresses[upload.uuid]);
            state.progresses = progresses;
        },
    };

    public actions = {
		
		async postDocumentProprietaire(context: ActionContext<UploadState, any>, {file, proprietaire}: { file: File, proprietaire: Proprietaire}): Promise<Upload> {
			return fileToUpload(
				file,
				upload => instance.uploadService.postDocumentProprietaire(proprietaire, upload),
				upload => {
					const document = new DocumentProprietaire();
					document.media = upload.media;
					proprietaire.addDocuments(document);
					storeProprietaire.dispatch('get', proprietaire.id);
				}
			);
		},
		
        async waitFinish(context: ActionContext<UploadState, any>, upload: Upload): Promise<Upload> {
            return await context.state.finishs[upload.uuid];
        }
    };

}
const instance = new UploadStore();
const storeUpload = new Vuex.Store(instance);
store.registerModule('upload', storeUpload);
export default storeUpload;
