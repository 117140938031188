






















































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Locataire} from '@/models';
import storeLocataire from '@/stores/modules/locataire';
import storeLogement from '@/stores/modules/logement';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";
import InputDatePicker from "@/components/Input/DatePicker.vue";
import CrudLogementListSelect from "@/components/Crud/Logement/ListSelect.vue";
import {Emit} from "vue-property-decorator";
import store from "@/stores";

@Component({
	components: {
		InputPhone,
		InputZipCode,
		InputDatePicker,
		CrudLogementListSelect,
	}
})
export default class CrudLocataireEditLocation extends AbstractEditVue<Locataire> {

	private storeLogement = storeLogement;


	public constructor() {
		super(
			storeLocataire,
			'Location modifié',
			'Locataire ajouté',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		await storeLocataire.dispatch('getC');
	}

	protected async onSubmit(): Promise<void> {
		await super.onSubmit();
	}

}
