




















































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Locataire, Proprietaire} from '@/models';

import CrudLocataireFilter from '@/components/Crud/Locataire/Filter.vue';
import CrudLocataireEditDialog from '@/components/Crud/Locataire/EditDialog.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeLocataireArchive from '@/stores/modules/locataire';
import CrudLocataireListArchive from "@/components/Crud/Locataire/ListArchive.vue";

@Component({
	components: {
		CrudLocataireFilter,
		CrudLocataireEditDialog,
		CrudLocataireListArchive,
		CrudDeleteDialog,
	}
})
export default class LocataireView extends Vue {

	private editLocataire: Locataire = null;
	private deletedLivraison: Locataire = null;
	private storeLocataireArchive = storeLocataireArchive;

	private refresh(): void {
		storeLocataireArchive.dispatch('getC');
	}

	@Emit()
	private onCreate(): void {
		this.editLocataire = new Locataire();
	}
}
