


























































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {AvisLoyer} from '@/models';
import storeAvisLoyer from '@/stores/modules/avisLoyer';
import storeLogement from '@/stores/modules/logement';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";
import InputDatePicker from "@/components/Input/DatePicker.vue";
import CrudLogementListSelect from "@/components/Crud/Logement/ListSelect.vue";
import {Emit} from "vue-property-decorator";
import store from "@/stores";

@Component({
	components: {
		InputPhone,
		InputZipCode,
		InputDatePicker,
		CrudLogementListSelect,
	}
})
export default class CrudAvisLoyerEditAvisLoyer extends AbstractEditVue<AvisLoyer> {

	private storeLogement = storeLogement;


	public constructor() {
		super(
			storeAvisLoyer,
			'Modification effectué',
			'AvisLoyer ajouté',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
	}

	protected async onSubmit(): Promise<void> {
		await super.onSubmit();
	}

}
