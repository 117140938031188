








































































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';

import storeLogement, {LogementFilter} from '@/stores/modules/logement';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {Logement, Proprietaire} from "@/models";
import CrudLogementEditDialog from "@/components/Crud/Logement/EditDialog.vue";
import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		CrudLogementEditDialog,
		CrudDeleteDialog,
	}
})
export default class CrudLogementList extends Vue {

	private editLogement: Logement = null;
	private deletedLogement: Logement = null;

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;

	private storeLogement = storeLogement;

	private search = '';

	private headers = [
		{
			text: 'Propriétaire',
			value: 'proprietaire.fullName',
		},
		{ text: 'Désignation', value: 'name', groupable: false  },
		{ text: 'Adresse', value: 'fullAddress', groupable: false  },
		{ text: 'Disponibilité', value: 'availability', groupable: false  },
		{ text: 'Actions', value: 'action', groupable: false, sortable: false  },
	];

	public mounted(): void {
		storeLogement.dispatch('getC');
	}

	private async onDeleteLogement(): Promise<void> {
		storeLogement.state.logements.data.splice(storeLogement.state.logements.data.findIndex(v => v.id == this.deletedLogement.id), 1);
		await storeLogement.dispatch('getC');
	}

	private async refreshLogement(): Promise<void> {
		await storeLogement.dispatch('getC');
	}

}
