




































































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';
import {InOut} from "vue-inout-prop-decorator/index";

import storeProprietaire, {ProprietaireFilter} from '@/stores/modules/proprietaire';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {Proprietaire} from "@/models";
import CrudProprietaireEditDialog from '@/components/Crud/Proprietaire/EditDialog.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
import store from "@/stores";

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		CrudProprietaireEditDialog,
		CrudDeleteDialog,
	}
})
export default class CrudProprietaireList extends Vue {

	private editProprietaire: Proprietaire = null;
	private deletedProprietaire: Proprietaire = null;

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;

	private storeProprietaire = storeProprietaire;

	private search = '';

	private headers = [
		{
			text: 'Nom/Société',
			value: 'fullName',
		},
		{ text: 'Adresse', value: 'fullAddress' },
		{ text: 'Téléphone', value: 'phone1' },
		{ text: 'Email', value: 'email' },
		{ text: 'Actions', value: 'action', sortable: false },
	];

	public async mounted(): Promise<void> {
		await storeProprietaire.dispatch('getC');
	}

	private async onDeleteProprietaire(): Promise<void> {
		storeProprietaire.state.proprietaires.data.splice(storeProprietaire.state.proprietaires.data.findIndex(v => v.id == this.deletedProprietaire.id), 1);
		await storeProprietaire.dispatch('getC');
	}

	private async refreshProprietaire(): Promise<void> {
		await storeProprietaire.dispatch('getC');
	}

}
