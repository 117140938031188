


































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';

import storeAvisLoyer from '@/stores/modules/avisLoyer';

import InputReglementsList from '@/components/Input/ReglementsList.vue';
import {AvisLoyer} from "@/models";

@Component({
	components: {
		InputReglementsList,
	}
})
export default class CrudAvisLoyerEditReglement extends AbstractEditVue<AvisLoyer> {
	public constructor() {
		super(
			storeAvisLoyer,
			'Réglement modifié',
			'Réglement créé',
		);
	}

}
