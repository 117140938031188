




















































































































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';

import storeAvisLoyer, {AvisLoyerFilter} from '@/stores/modules/avisLoyer';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {AvisLoyer} from "@/models/AvisLoyer";
import CrudAvisLoyerEditDialog from "@/components/Crud/AvisLoyer/EditDialog.vue";
import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";
import {
	DxDataGrid,
	DxColumn,
	DxEditing,
	DxFilterRow,
	DxHeaderFilter,
	DxGroupPanel,
	DxGrouping,
	DxScrolling,
	DxSummary,
	DxLookup,
	DxTotalItem,
	DxGroupItem,
	DxMasterDetail,
	DxStringLengthRule,
	DxRequiredRule,
	DxRangeRule,
	DxValueFormat,
	DxPaging,
	DxPager,
	DxStateStoring,
} from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import storeToken from "@/stores/modules/token";
import {Token} from "@/models";

@Component({
	components: {
		CrudDeleteDialog,
		CrudList,
		TableHead,
		TableCell,
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxFilterRow,
		DxHeaderFilter,
		DxGroupPanel,
		DxGrouping,
		DxScrolling,
		DxSummary,
		DxLookup,
		DxTotalItem,
		DxGroupItem,
		DxMasterDetail,
		DxStringLengthRule,
		DxRangeRule,
		DxRequiredRule,
		DxValueFormat,
		DxPaging,
		DxPager,
		DxStateStoring,
	}
})
export default class CrudAvisLoyerList extends Vue {

	private editAvisLoyer: AvisLoyer = null;
	private deletedAvisLoyer: AvisLoyer = null;
	private storeAvisLoyer = storeAvisLoyer;
	private dataGrid;

	@Prop({type: Boolean, default: true}) public paginate!: boolean;
	@Prop({type: Boolean, default: false}) public dense!: boolean;
	@Stored(() => storeAvisLoyer) private filters!: AvisLoyerFilter;
	@Stored(() => storeToken) token!: Token;

	private url = process.env.VUE_APP_BACK_BASE_URL + '/api/avis-loyer'

	private dataSource = createStore({
		key: 'id',
		loadUrl: `${this.url}/fetch`,
		onBeforeSend: (method, ajaxOptions) => {
			ajaxOptions.xhrFields = { withCredentials: true };
			ajaxOptions.headers = {
				'Authorization': 'BEARER ' + this.token.id
			}
		},
	});

	private calculateCellValue(data) {
		let date = data.date
		let year = date.substr(0,4)
		let month = date.substr(5,2)
		let day = date.substr(8,2)
		return day + '/' + month + '/' + year
	}

	private fullAddress(data) {
		return data.address + ' ' + data.zip_code + ' ' + data.town
	}

	public mounted(): void {
	}

	public deleteAvis(id) {
		let avis = new AvisLoyer()
		avis.id = id;
		this.deletedAvisLoyer = avis
		this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
	}

}
