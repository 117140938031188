




































































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';

import {LocataireFilter, storeLocataireArchive } from '@/stores/modules/locataire';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {Locataire} from "@/models/Locataire";
import CrudLocataireEditDialog from "@/components/Crud/Locataire/EditDialog.vue";
import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";
import {LogementFilter} from "@/stores/modules/logement";

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		CrudLocataireEditDialog,
		CrudDeleteDialog,
	}
})
export default class CrudLocataireListArchive extends Vue {

	private editLocataire: Locataire = null;
	private deletedLocataire: Locataire = null;

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;
	@Stored(() => storeLocataireArchive) private filters!: LocataireFilter;

	private storeLocataireArchive = storeLocataireArchive;

	private search = '';

	private headers = [
		{
			text: 'Numéro',
			value: 'number',
			groupable: false,
		},
		{ text: 'Locataire', value: 'fullName', groupable: false },
		{ text: 'Propriétaire', value: 'logement.proprietaire.fullName', groupable: false },
		{ text: 'Désignation du logement', value: 'logement.name', groupable: false },
		{ text: 'Adresse du logement', value: 'logement.fullAddress', groupable: false },
		{ text: 'Date d\'entrée', value: 'date', groupable: false },
		{ text: 'Loyer', value: 'amountRental', groupable: false },
		{ text: 'Reste dû', value: 'toPaid', groupable: false },
		{ text: 'Actions', value: 'action', groupable: false, sortable: false },
	];

	public mounted(): void {
		this.filters.state = [false];
		storeLocataireArchive.dispatch('getC', { filters: true });
	}

	private async onDeleteLocataire(): Promise<void> {
		storeLocataireArchive.state.locataires.data.splice(storeLocataireArchive.state.locataires.data.findIndex(v => v.id == this.deletedLocataire.id), 1);
		await storeLocataireArchive.dispatch('getC');
	}

	private async refreshLocataire(): Promise<void> {
		await storeLocataireArchive.dispatch('getC');
	}

}
