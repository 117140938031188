var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.storeUser.state.users)?_c('v-data-table',{staticStyle:{"filter":"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"},attrs:{"headers":_vm.headers,"items":_vm.storeUser.state.users.data},scopedSlots:_vm._u([{key:"item.enable",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.enable),callback:function ($$v) {_vm.$set(item, "enable", $$v)},expression:"item.enable"}})]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("space2nbsp")(item.phone))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt))+" ")]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._l((item.roles),function(role){return [(item.roles.length === 1 || role !== 'ROLE_USER')?[_vm._v(" "+_vm._s(role)),_c('br')]:_vm._e()]})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._t("action",null,{"item":item})]}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }