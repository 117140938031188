



































import {Component, Vue} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator";
import {User} from '@/models';
import {ResultType} from "@/shared/xhttp";

import storeUser from '@/stores/modules/user';

@Component({
	components: {
	}
})
export default class HomeView extends Vue {

	@Stored(() => storeUser) me!: User; 

	public created(): void {
		//console.log("created");
	}
	
	public mounted(): void {
		this.addEventDom(window, 'resize', () => this.$forceUpdate());

		//console.log("mounted");
	}
	
	private get chart_title(): string
	{
		return "Bois en stock";
	}
	
}
