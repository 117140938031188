


























import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeTva, {TvaFilter} from '@/stores/modules/tva';

import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';

@Component({
	components: {
		TrueFalseMultiSelect,
	}
})
export default class CrudTvaFilter extends Vue {
	
	@Prop({ type: Boolean, default: false}) reduce;
	
	@Stored(() => storeTva) private page!: number;
	@Stored(() => storeTva) private filters!: TvaFilter;
	
	@WatchObjectProperties('filters', TvaFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeTva.dispatch('getC', { filters: true });
	}
	
}
