






















































import {Component, Vue} from '@/shared/component';
import {Emit, Prop} from "vue-property-decorator";
import {Stored} from "vue-stored-prop-decorator";
import {Media, Token, Proprietaire} from "@/models";
import {DocumentProprietaire} from "@/models/DocumentProprietaire";

import storeToken from "@/stores/modules/token";
import storeDocumentProprietaire from "@/stores/modules/documentProprietaire";

@Component()
export default class CrudDocumentProprietaireShow extends Vue {
	@Prop({ type: DocumentProprietaire, required: true }) private document!: DocumentProprietaire;
	@Prop({ type: Proprietaire, required: true }) private proprietaire!: Proprietaire;

	@Stored(() => storeToken) private token!: Token;
	private confirm: boolean = false;

	private get media(): Media {
		return this.document.media;
	}

	private get href(): string {
		return this.media.hrefs['original']+'?token='+this.token.id;
	}

	private get apiUrl() {
		return process.env.VUE_APP_BACK_BASE_URL;
	}

	private get type() {
		let index = this.media.fileName.indexOf('.')
		return this.media.fileName.substr(index, this.media.fileName.length - index)
	}

	private get img(): string {
		if (this.media.type.indexOf('image/') === 0) {
			return 'image';
		}
		if (this.media.type.indexOf('video/') === 0) {
			return 'video';
		}

		switch (this.media.type) {
			case 'application/pdf':
			case 'application/xpdf':
				return 'pdf';
			case 'text/csv':
			case 'application/vnd.ms-excel':
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			case 'application/vnd.oasis.opendocument.spreadsheet':
				return 'calc';
			case 'application/msword':
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			case 'application/vnd.oasis.opendocument.text':
				return 'doc';
		}

		return 'file';
	}

	@Emit()
	private onDelete(): void {
		this.confirm = false;
		storeDocumentProprietaire.dispatch('delete', this.document);
	}
}
