







































import {Emit} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Tva} from '@/models';

import CrudTvaEdit from '@/components/Crud/Tva/Edit.vue';

@Component({
	components: {
		CrudTvaEdit,
	}
})
export default class CrudTvaEditDialog extends Vue {
	@InOut({ type: Tva  , isVModel: true }) private value!: Tva;

	@Emit()
	private onSubmit(tva: Tva) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
