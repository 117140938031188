






















import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {AvisLoyer, Proprietaire} from '@/models';

import CrudAvisLoyerFilter from '@/components/Crud/AvisLoyer/Filter.vue';
import CrudAvisLoyerEditDialog from '@/components/Crud/AvisLoyer/EditDialog.vue';
import CrudAvisLoyerList from '@/components/Crud/AvisLoyer/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeAvisLoyer from '@/stores/modules/avisLoyer';

@Component({
	components: {
		CrudAvisLoyerFilter,
		CrudAvisLoyerEditDialog,
		CrudAvisLoyerList,
		CrudDeleteDialog,
	}
})
export default class AvisLoyerView extends Vue {

}
