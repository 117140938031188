






























































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {ResultType} from '@/shared/xhttp';
import {Proprietaire} from '@/models';

import CrudProprietaireFilter from '@/components/Crud/Proprietaire/Filter.vue';
import CrudProprietaireShowDialog from '@/components/Crud/Proprietaire/ShowDialog.vue';
import CrudProprietaireEditDialog from '@/components/Crud/Proprietaire/EditDialog.vue';
import CrudProprietaireList from '@/components/Crud/Proprietaire/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';

import storeProprietaire, {ProprietaireFilter} from '@/stores/modules/proprietaire';

@Component({
	components: {
		CrudProprietaireFilter,
		CrudProprietaireShowDialog,
		CrudProprietaireEditDialog,
		CrudProprietaireList,
		CrudDeleteDialog,
	}
})
export default class ProprietaireView extends Vue {
	
	@Stored(() => storeProprietaire)
	private proprietaires!: ResultType<Proprietaire>;
	
	@Stored(() => storeProprietaire)
	private filters!: ProprietaireFilter;

	private showProprietaire: Proprietaire = null;
	private editProprietaire: Proprietaire = null;
	private deletedProprietaire: Proprietaire = null;
	private storeProprietaire = storeProprietaire;

	@Emit()
	private onCreate(): void {
		this.editProprietaire = new Proprietaire();
	}

	public mounted(): void {
		console.log("Mounted proprietaire");
		this.filters.clear();
	}

	private refresh(): void {
		storeProprietaire.dispatch('getC', { filters: true });
	}
}
