































































































































































































































































































































































































































import {Component} from '@/shared/component';
import {AvisLoyer, Locataire} from '@/models';
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
import CrudLocataireEditLocataireDialog from '@/components/Crud/Locataire/EditLocataireDialog.vue';
import CrudLocataireEditLocationDialog from '@/components/Crud/Locataire/EditLocationDialog.vue';
import CrudLocataireEditNumberDialog from '@/components/Crud/Locataire/EditNumberDialog.vue';
import CrudLocataireEditLoyerDialog from '@/components/Crud/Locataire/EditLoyerDialog.vue';

import storeLocataire from '@/stores/modules/locataire';
import {Emit} from "vue-property-decorator";
import CrudAvisLoyerEditDialog from "@/components/Crud/AvisLoyer/EditDialog.vue";
import storeAvisLoyer from "@/stores/modules/avisLoyer";
import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";

@Component({
	components: {
		CrudLocataireEditLocataireDialog,
		CrudLocataireEditLocationDialog,
		CrudLocataireEditNumberDialog,
		CrudLocataireEditLoyerDialog,
		CrudAvisLoyerEditDialog,
		CrudDeleteDialog,
	}
})
export default class CrudLocataireShow extends AbstractShowVue<Locataire> {

	private editLocataire: Locataire = null;
	private editLogement: Locataire = null;
	private editNumber: Locataire = null;
	private editLoyer: Locataire = null;

	private storeAvisLoyer = storeAvisLoyer;
	private deletedAvisLoyer: AvisLoyer = null;
	private editAvisLoyer: AvisLoyer = null;


	public constructor() {
		super(storeLocataire);
	}

	private async locationMarkState(locataire: Locataire): Promise<void> {
		await storeLocataire.dispatch('putMarkState', locataire);
		await storeLocataire.dispatch('get', this.value.id);
	}

	private async onDeleteAvis(): Promise<void> {
		await storeLocataire.dispatch('get', this.value.id);
	}

	private headers = [
		{
			text: 'Date',
			value: 'date',
			sortable: false
		},
		{text: 'N° Quittance', value: 'number_quittance', sortable: false},
		{text: 'Montant Total', value: 'rental', sortable: false},
		{text: 'Reste à payer', value: 'remaining', sortable: false},
		{text: 'Etat', value: 'state', sortable: false},
		{text: 'Actions', value: 'action', sortable: false},
	];

	@Emit()
	private onCreate(): void {
		this.editAvisLoyer = new AvisLoyer();
	}

}
