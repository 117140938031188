


















import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeLocataire, {LocataireFilter} from '@/stores/modules/locataire';

import InputNumberFilter from '@/components/Input/NumberFilter.vue';
import InputTva from '@/components/Input/Tva.vue';
import {ArrayHelper} from "@/shared/utils";

import InputDateFilter from '@/components/Input/DateFilter.vue';

@Component({
	components: {
		InputDateFilter,
		InputNumberFilter,
		InputTva,
	}
})
export default class CrudLocataireFilter extends Vue {

	@Prop({ type: Boolean, default: false}) public reduce: boolean;

	@Stored(() => storeLocataire) private page!: number;
	@Stored(() => storeLocataire) private filters!: LocataireFilter;

	@WatchObjectProperties('filters', LocataireFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeLocataire.dispatch('getC', { filters: true });
	}

	public mounted(): void {
		this.filters = new LocataireFilter();
	}

}
